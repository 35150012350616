<template>
    <div class="mt-8 lg:mt-20">
        <div v-if="!gotTheHarp">
          <div class="flex flex-col items-center mb-6 lg:mb-12">
            <p class="text-sm font-bold">{{$t('serial.family')}}</p>
            <div class="flex justify-center items-center mt-6">
              <input
                type="radio"
                class="flex justify-center items-center"
                id="lever"
                name="family"
                value="L"
                v-model="family"
              />
              <label for="lever" class="px-2">LEVER</label>
              <input
                type="radio"
                class="flex justify-center items-center ml-8"
                id="pedal"
                name="family"
                value="P"
                v-model="family"
              />
              <label for="pedal" class="px-2">PEDAL</label>
              <input
                type="radio"
                class="flex justify-center items-center ml-8"
                id="pedal"
                name="family"
                value="DH"
                v-model="family"
              />
              <label for="pedal" class="px-2">DELTA</label>
            </div>
          </div>
          <label class="font-bold text-sm">
              {{$t('serial.number')}}
          </label>
          <div class="flex flex-col w-full mx-auto">
              <input
                class="rounded-lg w-72 p-3 bg-beige-200 text-white font-number
                  font-bold text-xl text-center shadow-lg mt-3 lg:mt-6 mx-auto"
                  v-model="serial"
                  @keydown="this.badSerial = false"
                >
              <button class="text-beige-200 font-number flex justify-center items-center
              text-sm font-bold text-center lg:text-left mt-2 pl-5 w-full lg:w-1/4 mx-auto
                hover:text-black "
                  @click="openPictures">
                  {{$t('serial.find')}}
              </button>
          </div>
          <button
            class="uppercase mt-5 lg:mt-8"
            @click="findTheHarp"
          >
              {{$t('serial.confirm')}}
          </button>
          <div class="rounded-md bg-red-600 p-4 w-11/12 lg:w-1/4 mx-auto mt-4 lg:mt-10 shadow-lg"
            v-show="badSerial">
            <div class="flex">
              <div class="flex-shrink-0">
                <svg class="h-5 w-5 text-white cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                fill="currentColor" aria-hidden="true" @click="resetMe">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1
                  1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10
                  11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1
                  1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                </svg>
              </div>
              <div class="ml-3">
                <p class="text-sm font-medium text-white font-number">
                  {{errors}}
                  <a v-if="showEmail" href="mailto:service@salviharps.com"
                  class="font-bold underline">
                    service@salviharps.com
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div v-show="harpPictures" class="flex flex-col justify-center bg-beige-200
              items-center lg:w-full mt-10">
              <img src="../images/harps.png" class="w-full lg:w-2/5">
              <p class="text-white font-bold mt-4 text-sm lg:text-lg px-2 lg:px-0">
                  {{$t('serial.find')}}
              </p>
              <p class="text-white pt-1 pb-3 text-xs lg:text-lg px-2 lg:px-0">
                  {{$t('serial.where')}}
              </p>
          </div>
        </div>

        <div v-else>
          <harp-viewer></harp-viewer>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { useStore } from 'vuex';
import { computed } from 'vue';
import HarpViewer from '@/components/HarpViewer.vue';

export default {
  components: {
    HarpViewer,
  },

  setup() {
    const store = useStore();

    const good = computed(() => store.getters.harp);
    const addHarp = (harp) => store.dispatch('addHarp', harp);

    return {
      good,
      addHarp,
    };
  },

  data() {
    return {
      serial: '',
      badSerial: false,
      harpPictures: false,
      gotTheHarp: false,
      family: 'P',
      errors: '',
      showEmail: false,

    };
  },

  computed: {
    badSerialPrefixForPedal() {
      return this.family === 'P' && (!this.uppercaseSerial.startsWith('P1') && !this.uppercaseSerial.startsWith('P2'));
    },
    badSerialPrefixForLever() {
      return this.family === 'L' && (!this.uppercaseSerial.startsWith('L3') && !this.uppercaseSerial.startsWith('L4'));
    },
    badSerialForDelta() {
      return this.family === 'DH' && !this.uppercaseSerial.startsWith('DH');
    },
    checkBadSerial() {
      return this.badSerialPrefixForPedal || this.badSerialPrefixForLever || this.badSerialForDelta;
    },
    uppercaseSerial() {
      return this.serial.toUpperCase();
    },
  },

  watch: {
    family() {
      if (this.badSerial) {
        this.badSerial = false;
      }
    },
  },

  methods: {
    openPictures() {
      this.harpPictures = true;
    },
    resetMe() {
      this.badSerial = false;
      this.serial = '';
      this.errors = '';
    },
    findTheHarp() {
      this.badSerial = false;
      this.errors = '';

      if (this.checkBadSerial) {
        this.badSerial = true;
        this.errors = this.$t(`serial.bad_${this.family}`);
        return;
      }

      axios({
        method: 'get',
        url: `${process.env.VUE_APP_BEES_URL}/vendite/beni/${this.uppercaseSerial}?lang=${this.$i18n.locale}`,
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${process.env.VUE_APP_BEES_TOKEN}`,
        },
      }).then(({ data }) => {
        if (data === 'Arpa Non Trovata') {
          this.badSerial = true;
          this.errors = this.$t(`serial.not_found_${this.family}`);
          this.showEmail = true;
          return;
        }
        const good = data.data[0];
        this.addHarp({
          family: this.family,
          ...good,
        });
        this.gotTheHarp = true;
      });
    },
  },
};
</script>
