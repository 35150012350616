<template>
    <div class="flex flex-col lg:flex-row justify-center w-full
      items-center bg-beige-200 py-4 lg:py-0">
        <img :src="`harps/${good.cod_articolo}.jpg`"
          class="w-0 lg:w-56 pt-2 lg:pt-0 invisible lg:visible">
        <form class="flex flex-col justify-center lg:justify-around ml-0 lg:ml-6 my-3
          w-11/12 lg:w-1/3">
            <label class="uppercase text-white font-semibold text-left ml-2">
                {{$t('registration.harp')}}</label>
            <input class="p-1 font-number rounded pl-2" v-model="good.des_articolo">
            <label class="uppercase text-white font-semibold text-left mt-5 ml-2">
                {{$t('registration.finish')}}</label>
            <input class="p-1 font-number rounded pl-2" v-model="good.des_config">
            <label class="uppercase text-white font-semibold text-left mt-5 ml-2">
                {{$t('registration.serial')}}</label>
            <input class="p-1 font-number rounded pl-2" v-model="good.matricola">
            <router-link to='/customer-form' class="uppercase text-white mt-4 lg:mt-8">
              {{$t('serial.confirm')}}
            </router-link>
        </form>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  setup() {
    const store = useStore();

    const good = computed(() => store.getters.harp);

    return {
      good,
    };
  },
};
</script>
